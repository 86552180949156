import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import {
  FaLightbulb,
  FaUserFriends,
  FaPeopleCarry
} from 'react-icons/fa';

import { EarlyAccessLocumForm } from '../forms/EarlyAccessLocumForm';
import { DreamFeatureForm } from '../forms/DreamFeature';
import LatestArticles from '../LatestArticles'

const HomePageTemplate = ({
  title,
  subtitle,
  meta_title,
  meta_description
}) => {

  return <div>
    <Helmet>
      <title>{meta_title}</title>
      <meta name='description' content={meta_description} />
    </Helmet>
    <section className='hero is-primary is-bold'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns is-vcentered'>

            <div className='column is-12-mobile is-6' >
              <div className='section'>
                <h1 className='title has-text-weight-semibold is-size-3'>
                  Find your locum jobs with Locumago
                </h1>
                <h2 className='is-size-5'>
                  {subtitle}
                </h2>

                <div className='is-10-mobile is-12'>
                  <EarlyAccessLocumForm />
                </div>
              </div>
            </div>

            <div className="column is-6 is-flex is-horizontal-center is-hidden-mobile">
              <figure className="image is4by3 mt-2">
                <img src="img/locumago-app.png"></img>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section id="locumapp-features" className='section section--gradient is-medium'>
      <div className="container">
        <p className="title has-text-centered mb-1 has-text-grey">The Next Generation Locum App</p>
      </div>

      <div className='container'>
        <div className='section'>
          <div className='columns is-centered has-text-centered is-variable is-8-widescreen is-3-desktop is-2-tablet is-0-mobile'>

            <div className='column is-4 mb-3'>
              <div className='feature'>
                <h4 className='title is-4 has-text-grey'>Innovative</h4>
                <figure className="image is-128x128 has-image-centered mb-1">
                  <FaLightbulb size={128} color={'#f7e151'} />
                </figure>
                <p>An innovative mobile application in the market to change the system of looking and acquiring locum jobs</p>
              </div>
            </div>

            <div className='column is-4 mb-3'>
              <div className='feature'>
                <h4 className='title is-4 has-text-grey'>Peer-minded</h4>
                <figure className="image is-128x128 has-image-centered mb-1">
                  <FaUserFriends size={128} color={'#69c4a6'} />
                </figure>
                <p>Created by pharmacist for pharmacist</p>
              </div>
            </div>

            <div className='column is-4'>
              <div className='feature'>
                <h4 className='title is-4 has-text-grey'>Supportive</h4>
                <figure className="image is-128x128 has-image-centered mb-1">
                  <FaPeopleCarry size={128} color={'#0000ff'} />
                </figure>
                <p>Community driven and giving back is one of our core values</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

    <section id='locum-build-your-dream-app' className='section section--gradient is-light-green is-medium'>
      <div className='container'>
        <p className='title has-text-centered has-text-grey mb-1'>Help us build your Dream App</p>
      </div>
      <div className='container'>
        <div className='pt-2'>
          <div className='columns is-centered has-text-centered is-variable is-8-widescreen is-3-desktop is-2-tablet is-0-mobile'>
            <div className='column is-4 mb-3'>
              <DreamFeatureForm source='locums' type='Ideal' />
            </div>

            <div className='column is-4 mb-3'>
              <DreamFeatureForm source='locums' type='Realistic' />
            </div>

            <div className='column is-4 mb-3'>
              <DreamFeatureForm source='locums' type='Dream' />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="locum-latest-blog" className='section section--gradient is-medium'>
      <div className="container">
        <p className="title has-text-centered mb-2 has-text-grey">Check out our Latest Blog</p>
      </div>
      <div className="container">
        <LatestArticles />
      </div>
    </section>

    <section id='locum-early-access' className='section section--gradient is-aliceblue'>
      <div className='container'>
        <p className='title has-text-centered has-text-grey mb-1'>Sounds great! Sign up to get early access.</p>

        <div className='columns is-vcentered  is-centered'>
          <div className='column is-12-mobile is-6' >
            <div className='is-10-mobile is-12'>
              <EarlyAccessLocumForm />
            </div>
          </div>
        </div>
      </div>
    </section>
    
  </div>
}

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
}

export default HomePageTemplate

